import './polyfill/dataset-polyfill';
import './polyfill/object-foreach-polyfill';
import './polyfill/promise-polyfill';
import './polyfill/object-assign-polyfill';
import './polyfill/custom-event-polyfill';
import './polyfill/string-includes-polyfill';

// set basepath of project for webpack
import { basePath } from './core/config';
__webpack_public_path__ = `${basePath}/assets/frontend/js/`;

import 'console-polyfill';

import FastClick from 'fastclick';

// GTM swup
document.addEventListener('swup:contentReplaced', event => {
    if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
            'event': 'VirtualPageview',
            'virtualPageURL': window.location.pathname,
            'virtualPageTitle': document.title
        });
    } else {
        console.warn('GTM is not loaded.')
    }
});

import './core/swup';

if (swup.detectie() == 11) {
    document.documentElement.classList.add('small-gaps')
    document.documentElement.classList.add('no-sticky-menu')
}

import { loadComponents } from './core/componentUtils'
loadComponents()