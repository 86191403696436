import Swup from 'swup';
import Component from './Component';
import { loadComponents, removeComponents } from './componentUtils';

let menu;

setTimeout(() => {
    const menuElement = document.querySelector('[data-component="Nav"]');
    menu = Component.getFromElement(menuElement);
}, 10)

class CustomSwupjs extends Swup {
    // this is here for IE to add data attributes

    constructor(setOptions) {
        super(setOptions)
        this.markSwupElements = function(element) {
            let blocks = 0;

            for (var i = 0; i < this.options.elements.length; i++) {
                if (element.querySelector(this.options.elements[i]) == null) {
                    console.warn(`Element ${this.options.elements[i]} is not in current page.`)
                } else {
                    [].forEach.call(document.body.querySelectorAll(this.options.elements[i]), (item, index) => {
                        element.querySelectorAll(this.options.elements[i])[index].dataset.swup = blocks;
                        element.querySelectorAll(this.options.elements[i])[index].setAttribute('data-swup', blocks);
                        blocks++;
                    });
                }
            }
        }
    }
}


let options = {
    debugMode: true,
    //animations: animations,
    preload: true,
    doScrollingRightAway: true,
    //animateScroll: false,
    elements: ['#header', '#swup', '#footer', '#nav'],
}
if(window.innerWidth <= 767) {
    options.animateScroll  = false;
    options.doScrollingRightAway = false;
}

export const swup = new CustomSwupjs(options);

document.addEventListener('swup:pageView', event => {
    swup.markSwupElements(document.documentElement);
});
swup.markSwupElements(document.documentElement);

document.addEventListener('swup:contentReplaced', event => {
    setTimeout(() => {
        document.querySelectorAll('[data-swup]').forEach(element => {
            loadComponents(element)
        });
    }, 10);
});

document.addEventListener('swup:animationOutDone', event => {
    document.querySelectorAll('[data-swup]').forEach(element => {
        removeComponents(element);
    });
});

document.addEventListener('swup:animationInDone', event => {
    // remove "to-{page}" classes
    document.documentElement.classList.remove('is-changing')
    document.documentElement.classList.remove('is-rendering')
});


